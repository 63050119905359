import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Equipement } from 'src/app/core/shared/models/equipement';
import { HeaderService } from './services/header.service';
import { Company } from 'src/app/core/shared/models/company.model';
import { Status } from 'src/app/core/shared/models/Status';
import * as Notiflix from 'notiflix';
import { OpenWorkorderService } from 'src/app/pages/open-workorder/services/open-workorder.service';
import { StorageService } from 'src/app/core/shared/services/storage.service';
import { TaskControlService } from 'src/app/core/shared/services/task-control.service';
import { StatusProfileBlockAndReturnScan } from 'src/app/core/shared/models/statusProfileBlockAndReturnScan.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Profile } from 'src/app/core/shared/models/profile';
declare var bootstrap: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() equipement: Equipement | undefined = null
  @Input() BIXI_userAuthObject: any = null
  @Input() currentUserCompany: Company | undefined = null
  @Output() refreshEquipementEvent = new EventEmitter<object>();
  @Output() sendBtReportToSharepointEvent = new EventEmitter<object>();

  statusArray: Status[] = []
  disableButton = true
  newStatus = '';
  defaultFinishedStatus = '';
  isStatusSavePDF = false;
  eraAutorisedStatus: any[] = []
  @Input() pageName: string = ''
  fakedDataButImportantInApp = null
  isAccesChangeStatusOption = false;
  @Output() maganePermssionEvent = new EventEmitter<object>();
  
  isConfirmationPopup = false;
  isGenerateReturnOT = false;
  isCheckValidationOfLines = false;
  confirmValidationStatusModalEvent: Subject<any> = new Subject<any>();
  isStatusBlockedForProfil = false;

  selectedValue: string = "";
  constructor(private headerService: HeaderService,
    private openWorkOrderService: OpenWorkorderService,
    private storageService: StorageService,
    private taskControlService: TaskControlService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
     ) { 
  }

  ngOnInit() {
    Notiflix.Notify.init({
      position: 'right-bottom'
    });
    this.getWorkOrdersWithExpand()
    this.headerService.newMultipleCorrectifWOrkOrderEquipement.subscribe(data => { 
    this.fakedDataButImportantInApp = data
    this.equipement.workOrderNo = data?.BT?.no
    this.equipement.workOrderId = data?.BT?.systemId
    this.equipement.workOrderBixiStatusDesc = data?.BT?.workOrderBixiStatusDesc
    this.getWorkOrdersWithExpand()
    })
  }

  getWorkOrdersWithExpand(){
    this.openWorkOrderService.getWorkordersWithExpand(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, "no eq '"+ this.equipement.workOrderNo +"'", "$expand=eraAutorisedStatus").subscribe({
      next: (workOrder) => {
        if(workOrder.value.length > 0){
          this.eraAutorisedStatus = workOrder.value[0].eraAutorisedStatus.sort((a, b) => (a.codeStatusDestination.toLowerCase() > b.codeStatusDestination.toLowerCase()) ? 1 : -1);
          this.defaultFinishedStatus = workOrder.value[0]?.defaultFinishedStatus;
        }   
      },
      error: (error) => {}
    })
  }

  onAuthorizedStatusChange(event){
    let selectdStatut = event.target.value;
    this.newStatus =selectdStatut;
    this.isStatusSavePDF = this.eraAutorisedStatus?.find(x=>x.codeStatusDestination == selectdStatut)?.savepdf || false;
    this.isConfirmationPopup = this.eraAutorisedStatus?.find(x=>x.codeStatusDestination == selectdStatut)?.confirmationPopup || false;
    this.isGenerateReturnOT = this.eraAutorisedStatus?.find(x=>x.codeStatusDestination == selectdStatut)?.generateReturnOT || false;
    this.isCheckValidationOfLines = this.eraAutorisedStatus?.find(x=>x.codeStatusDestination == selectdStatut)?.confirmationPopup || false;

    if(selectdStatut?.trim() !== ''){
      this.disableButton = false
    }else{
      this.disableButton = true
    }
  }


  changeStatusProcess(){
    this.isAccesChangeStatusOption = window.location.href.includes('detailworkorder') ? true : false;
    if(window.location.href.includes('detailworkorder')){
      var myModal = new bootstrap.Modal(document.getElementById('workOrderBixiStatusModal'),{});
      myModal.toggle();
    }
    else{
        Notiflix.Notify.info(`Le changement de statut BT peut être fait à partir de la page du Détail BT`, {
          showOnlyTheLastOne: true, 
          width: "400px",
        });
    }
  }

  saveStatus(){
    if(window.location.href.includes('detailworkorder')){
      if(this.newStatus?.trim() !== ''){
        if(this.isConfirmationPopup){
          this.checkchangeStatus();
        }
         else{
           this.saveStatusNoConfirmationPopup();
        }      
      }
    }
    else{
      Notiflix.Notify.info(`Le changement de statut BT peut être fait à partir de la page du Détail BT`, {
        showOnlyTheLastOne: true, 
        width: "400px",
      });      
    }    
  }

  saveStatusNoConfirmationPopup(){
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
    let currentProfile: Profile  = JSON.parse(
      this.storageService.getItem('bixi-currentProfile')
    );
    const userProfile = currentProfile?.profil
    
    this.activatedRoute.params.subscribe((params) => {
      let tempWorkOrder = params['workOrderNo'];

      if (tempWorkOrder.includes(',')) {

        const data = { "newStatus": this.newStatus, "userProfil": userProfile, "isMultiBt": true }
        this.headerService.editStatus(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.equipement.workOrderId, JSON.stringify(data)).subscribe({
          next: (data) => {
            document.getElementById('closeWorkorderStatusModal').click()
            Notiflix.Notify.success(`Statut du bon de travail modifié avec succès`, {
              showOnlyTheLastOne: true, 
              width: "400px",
            });  

            this.getBlockActionsByStatusProfile(userProfile, this.newStatus);  
          
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            })
        }
        })
        
      } else {
        const data = { "newStatus": this.newStatus, "userProfil": userProfile, "isMultiBt": false }
        this.headerService.editStatus(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.equipement.workOrderId, JSON.stringify(data)).subscribe({
          next: (data) => {
            document.getElementById('closeWorkorderStatusModal').click()
            Notiflix.Notify.success(`Statut du bon de travail modifié avec succès`, {
              showOnlyTheLastOne: true, 
              width: "400px",
            });  

            this.getBlockActionsByStatusProfile(userProfile, this.newStatus);   
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            })
        }
        }) 
      }
    })    
  }

  checkchangeStatus(){
    let currentProfile: Profile  = JSON.parse(
      this.storageService.getItem('bixi-currentProfile')
    );
    const userProfile = currentProfile?.profil  
    const data = { "newStatus": this.newStatus, "userProfil": userProfile};
    const selectdStatutDesc = this.eraAutorisedStatus?.find(x=>x.codeStatusDestination == this.newStatus)?.statusDestinationDescription;
    Notiflix.Loading.standard('Chargement...', {
      svgColor: '#ffffff'
    });
    this.headerService.checkchangeStatus(this.BIXI_userAuthObject.tenantId, this.currentUserCompany.id, this.equipement.workOrderId, JSON.stringify(data)).subscribe({
      next: (data) => {
        document.getElementById('closeWorkorderStatusModal').click();
        this.confirmValidationStatusModalEvent.next({
          generateReturnOT: this.isGenerateReturnOT,
          checkValidationOfLines: this.isCheckValidationOfLines,
          workorderNo: this.equipement.workOrderNo,
          workorderSysId: this.equipement.workOrderId,
          nextWoStatus: this.newStatus,
          nextWoStatusDesc: selectdStatutDesc,
          defaultFinalStatus: this.defaultFinishedStatus,
          isBtRoue: this.equipement.multiCorrectifWorkOrder
        });        
      },
      error: (error) => {
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error, {
          timeout: 3000,
          showOnlyTheLastOne: true, 
          width: "400px",
        })
    }
    })          
  }

  getBlockActionsByStatusProfile(userProfileName, newStatus){   
    const workOrderLedSysId = this.storageService.getItem("Bixi_WorkOrderLedSysId");
    this.taskControlService.blockActionsByStatusBtProfile(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany?.id,
          newStatus,
          userProfileName,
          workOrderLedSysId   
      ).subscribe({
        next: (permissions) => {
          this.storageService.setBlockAndReturnScanObject(userProfileName, newStatus, workOrderLedSysId, permissions.isBlockedAction, permissions.isRetrunScan);
          this.mainProcessAfterSaveStatus();
        },
        error: (error) => {
          this.storageService.setBlockAndReturnScanObject(userProfileName, newStatus, workOrderLedSysId, true, true)
          Notiflix.Loading.remove();
          this.mainProcessAfterSaveStatus();
        },
    });    
  } 
  
  private mainProcessAfterSaveStatus(){
    this.getWorkOrdersWithExpand()
    if(this.fakedDataButImportantInApp){
      setTimeout(() => { 
        this.equipement.workOrderNo = this.fakedDataButImportantInApp.BT.no           
        this.equipement.workOrderId = this.fakedDataButImportantInApp.BT.systemId
        this.openWorkOrderService
        .getWorkorders(
          this.BIXI_userAuthObject.tenantId,
          this.currentUserCompany.id,
          "no eq '" + this.fakedDataButImportantInApp.BT.no + "'"
        )
        .subscribe({
          next: (workOrder) => {
          this.equipement.workOrderBixiStatusDesc =  workOrder.value[0].workOrderBixiStatusDesc
          },
          error: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(error, {
              timeout: 3000,
              showOnlyTheLastOne: true, 
              width: "400px",
            });
          },
        });
      }, 500)
    }
    if(this.isStatusSavePDF){
      this.sendBtReportToSharepointEvent.emit();
      this.isStatusSavePDF = false;
    } 
    else{
      let BlockAndReturnScan: StatusProfileBlockAndReturnScan  = JSON.parse(this.storageService.getItem('Bixi_BlockAndReturnScan'));
      let isScanReturn = BlockAndReturnScan ? BlockAndReturnScan?.isRetrunScan : false  
      if(isScanReturn){
        let currentProfile = this.storageService.getItem('bixi-currentProfile') ? JSON.parse(this.storageService.getItem('bixi-currentProfile'))?.id : "";
        this.router.navigateByUrl(`home/${this.currentUserCompany.id}/${currentProfile}`);  
        Notiflix.Loading.remove();      
      }
      else if(BlockAndReturnScan?.currentStatus == 'REP-VALID'){
        
        let currentProfile = this.storageService.getItem('bixi-currentProfile') ? JSON.parse(this.storageService.getItem('bixi-currentProfile'))?.id : "";
        this.router.navigateByUrl(`home/${this.currentUserCompany.id}/${currentProfile}`);  
      }
      else{
        this.refreshEquipementEvent.emit();
      }    
    }             
  }
}
